import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Pizzas from '../components/pizzas';
import PizzasDoces from '../components/pizzasDoces';
import Bordas from '../components/bordas';
import Bebidas from '../components/bebidas';

const StyledAppBar = styled(AppBar)`
  background-color: #00a85a;
  color: #ffffff;
  margin-bottom: 15px;
`;

const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #ffffff;
    height: 3px;
  }

  .MuiTabs-scrollButtons {
    color: #ffffff;
  }

  @media (min-width: 580px) {
    .MuiTabs-flexContainer {
      justify-content: center;
      /* Only use if the number of tab don't need to scroll */
    }
  }
`;

const Menu = styled.main`
  padding: 15px 0 45px;

  .menu-title {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 45px;
    text-transform: uppercase;
  }

  .menu-subtitle {
    text-align: center;
    margin-top: -35px;
    margin-bottom: 35px;
    font-size: 0.875rem;
    color: #00a85a;
    margin-left: 0;
    list-style: none;
    padding-left: 30px;
    padding-right: 30px;

    li {
      margin-bottom: 3px;
    }
  }
`;

const IndexPage = () => {
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function handleChangeIndex(index) {
    setValue(index);
  }

  return (
    <Layout>
      <SEO
        title="Pizzaria Itália Cardápio"
        keywords={[
          `pizzas`,
          `pizzas doces`,
          `sucos`,
          `bebidas`,
          `disk entregas`,
          `pizza delivery`,
          `forno a lenha`,
        ]}
      />

      <StyledAppBar
        position="sticky"
        color="default"
        square={true}
        elevation={1}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="on"
        >
          <Tab label="Pizzas" />
          <Tab label="Pizzas Doces" />
          <Tab label="Bordas" />
          <Tab label="Bebidas" />
        </StyledTabs>
      </StyledAppBar>

      <SwipeableViews
        index={value}
        onChangeIndex={handleChangeIndex}
        animateHeight
      >
        <Menu className="menu">
          <h1 className="menu-title">Pizzas</h1>
          <ul className="menu-subtitle">
            <li>Preços válidos somente para entrega e viagem</li>
            <li>Taxa de entrega diferenciada por bairro</li>
            <li>Pizzas meio-a-meio prevalecerá o valor maior</li>
          </ul>

          <Pizzas />
        </Menu>

        <Menu className="menu">
          <h1 className="menu-title">Pizzas Doces</h1>

          <ul className="menu-subtitle">
            <li>Preços válidos somente para entrega e viagem</li>
            <li>Taxa de entrega diferenciada por bairro</li>
            <li>Pizzas meio-a-meio prevalecerá o valor maior</li>
          </ul>

          <PizzasDoces />
        </Menu>

        <Menu className="menu">
          <h1 className="menu-title">Bordas</h1>

          <ul className="menu-subtitle">
            <li>
              Borda de Requeijão grátis de Segunda à Quinta, exceto: feriados e
              vésperas
            </li>
          </ul>

          <Bordas />
        </Menu>

        <Menu className="menu">
          <h1 className="menu-title">Bebidas</h1>

          <Bebidas />
        </Menu>
      </SwipeableViews>
    </Layout>
  );
};

export default IndexPage;
