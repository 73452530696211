import styled from 'styled-components';

export const Item = styled.div`
  padding-left: 30px;
  border-top: ${props => (props.firstItem ? '1px solid #ccc' : 'none')};
  border-bottom: 1px solid #ccc;
  color: #ec3237;
  display: flex;
  justify-content: space-between;
  align-content: stretch;

  @media (max-width: 375px) {
    padding-left: 15px;
  }

  @media (min-width: 768px) {
    padding-left: 80px;
    padding-right: 80px;
  }

  .badge {
    display: block;
    font-size: 0.625rem;
    background-color: #ec3237;
    border-radius: 3px;
    color: #ffffff;
    width: 70px;
    text-align: center;
  }

  .item-separator {
    padding: 20px 5px 20px 0;
    align-self: center;
  }

  .item-title {
    text-transform: uppercase;
    color: #ec3237;
  }

  .item-price-container {
    display: flex;
    flex-direction: column;
  }

  .item-price-wrapper {
    display: flex;
    height: 100%;
  }

  .item-price-size {
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;

    p {
      margin-bottom: 0;
    }
  }

  .bgcolor-size1 {
    background-color: #00a85a;
  }
  .bgcolor-size2 {
    background-color: #18b96e;
  }
  .bgcolor-size3 {
    background-color: #2cc07b;
  }

  .item-price {
    font-size: 1.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100px;
    font-weight: 600;

    span {
      font-size: 0.75rem;
      text-transform: uppercase;
    }
    p {
      margin-bottom: 0;
    }
  }

  .item-desc {
    color: #777;
    margin-top: 5px;
    margin-bottom: 0;
  }
`;
