import React from 'react';
import styled from 'styled-components';
import Slide from 'react-reveal/Slide';

import { Item } from '../styles/styles.js';

const TitleSeparator = styled.h2`
  margin-top: 30px;
  margin-left: 30px;
  color: #00a85a;
`;

const Bebidas = () => (
  <>
    <Slide left cascade duration={300}>
      <TitleSeparator>Refrigerantes</TitleSeparator>
    </Slide>

    <Item firstItem>
      <div className="item-separator">
        <h4 className="item-title">Refrigerante Lata</h4>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price">
            <p>5,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">Refrigerante 600ml</h4>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price">
            <p>6,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">Refrigerante 1L</h4>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price">
            <p>8,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">Refrigerante 2L</h4>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price">
            <p>13,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Slide left cascade duration={300}>
      <TitleSeparator>Cervejas</TitleSeparator>
    </Slide>

    <Item firstItem>
      <div className="item-separator">
        <h4 className="item-title">Cerveja Lata Brahma</h4>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price">
            <p>5,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">Cerveja Heineken Lonk Neck</h4>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price">
            <p>8,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">Cerveja Budweiser / Brahma 600ml</h4>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price">
            <p>10,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">Heineken / Original 600ml</h4>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price">
            <p>13,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Slide left cascade duration={300}>
      <TitleSeparator>Vinho</TitleSeparator>
    </Slide>

    <Item firstItem>
      <div className="item-separator">
        <h4 className="item-title">Vinho Garrafa seco/suave</h4>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price">
            <p>25,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Slide left cascade duration={300}>
      <TitleSeparator>Suco</TitleSeparator>
    </Slide>

    <Item firstItem>
      <div className="item-separator">
        <h4 className="item-title">Suco Lata</h4>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price">
            <p>5,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Slide left cascade duration={300}>
      <TitleSeparator>Água</TitleSeparator>
    </Slide>

    <Item firstItem>
      <div className="item-separator">
        <h4 className="item-title">Água sem Gás 500ml</h4>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price">
            <p>3,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">Água com Gás 500ml</h4>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price">
            <p>3,50</p>
          </div>
        </div>
      </div>
    </Item>
  </>
);

export default Bebidas;
