import React from 'react';

import { Item } from '../styles/styles.js';

const PizzasDoces = () => (
  <>
    <Item firstItem>
      <div className="item-separator">
        <h4 className="item-title">
          <span className="badge">Promoção</span> 88 - Banana
        </h4>

        <p className="item-desc">Banana, açucar e canela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>41,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>43,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>45,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>53,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">89 - Banana com Chocolate</h4>

        <p className="item-desc">Chocolate Nestlé coberto com banana.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">90 - Banana Nevada</h4>

        <p className="item-desc">
          Banana fatiada coberta com chocolate branco gratinado
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>65,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>67,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>69,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>77,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">
          <span className="badge">Promoção</span> 91 - Beijinho
        </h4>

        <p className="item-desc">Massa de beijinho, leite condensado e coco.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>41,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>43,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>45,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>53,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">92 - Brigadeiro</h4>

        <p className="item-desc">Chocolate Nestlé, brigadeiro e granulado.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>57,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>69,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">93 - Califórnia</h4>

        <p className="item-desc">
          Lombo canadense, abacaxi, figo, ameixa, pêssego salpicada com
          mussarela.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">
          <span className="badge">Promoção</span> 94 - Doce de Leite
        </h4>

        <p className="item-desc">Doce de leite com coco.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>41,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>43,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>45,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>53,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">95 - Kids</h4>

        <p className="item-desc">Chocolate Nestlé coberto com confetes.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">96 - Leite Ninho</h4>

        <p className="item-desc">
          Creme de leite Ninho decorado com creme de avelã.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>66,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>74,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">97 - Merengue</h4>

        <p className="item-desc">Chocolate branco, morango e suspiro.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>66,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>74,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">98 - Oreo</h4>

        <p className="item-desc">Chocolate branco coberto com biscoito Oreo.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">99 - Ouro Branco</h4>

        <p className="item-desc">
          Chocolate Nestlé coberto com bombons Ouro Branco.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">100 - Prestígio</h4>

        <p className="item-desc">Chocolate Nestlé, coco ralado e cereja.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>57,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>69,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">
          <span className="badge">Promoção</span> 101 - Romeu e Julieta
        </h4>

        <p className="item-desc">Mussarela e goiabada.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>41,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>43,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>45,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>53,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">102 - Sensação</h4>

        <p className="item-desc">Creme de avelã coberto com morangos.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>66,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>74,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">103 - Sonho de Valsa</h4>

        <p className="item-desc">
          Chocolate Nestlé coberto com bombons Sonho de Valsa.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>
      </div>
    </Item>
  </>
);

export default PizzasDoces;
