import React from 'react';
import styled from 'styled-components';
import Slide from 'react-reveal/Slide';

import { Item } from '../styles/styles.js';

const TitleSeparator = styled.h2`
  margin-top: 30px;
  margin-left: 30px;
  color: #00a85a;
`;

const Pizzas = () => (
  <>
    <Slide left cascade duration={300}>
      <TitleSeparator>Queijo</TitleSeparator>
    </Slide>

    <Item firstItem>
      <div className="item-separator">
        <h4 className="item-title">01 - Cheddar</h4>

        <p className="item-desc">
          Queijo cheddar, bacon, champignon e mussarela.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">
          <span className="badge">Promoção</span> 02 - Dois Queijos
        </h4>

        <p className="item-desc">Mussarela e catupiry.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>41,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>43,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>45,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>53,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">03 - Italiana</h4>

        <p className="item-desc">
          Mussarela, champignon, tomate, pimentão e cebola.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>71,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">04 - Três Queijos</h4>

        <p className="item-desc">Mussarela, catupiry e provolone.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>56,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>68,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">05 - Quatro Queijos</h4>

        <p className="item-desc">Mussarela, catupiry, parmesão e provolone.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>70,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">06 - Cinco Queijos</h4>

        <p className="item-desc">
          Mussarela, catupiry, gorgonzola, parmesão e provolone.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">07 - Seis Queijos</h4>

        <p className="item-desc">
          Mussarela, parmesão, provolone, catupiry, cheddar e gorgonzola.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>65,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>73,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">08 - Grega</h4>

        <p className="item-desc">
          Mussarela, champignon, catupiry, e provolone.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>57,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>69,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">
          <span className="badge">Promoção</span> 09 - Mussarela
        </h4>

        <p className="item-desc">Mussarela e tomate.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>41,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>43,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>45,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>53,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">10 - Mussarela Crocante</h4>

        <p className="item-desc">Mussarela, catupiry e batata palha.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>57,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>69,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">11 - Margherita</h4>

        <p className="item-desc">Mussarela, tomate, parmesão e manjericão.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>54,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>56,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>66,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Slide left cascade duration={300}>
      <TitleSeparator>Calabresa</TitleSeparator>
    </Slide>

    <Item firstItem>
      <div className="item-separator">
        <h4 className="item-title">
          <span className="badge">Promoção</span> 12 - Baiacatu
        </h4>

        <p className="item-desc">Calabresa moída, catupiry e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>41,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>43,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>45,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>53,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">13 - Baiana</h4>

        <p className="item-desc">
          Calabresa apimentada, ovo, cebola, mussarela e manjericão.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>54,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>56,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>66,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">
          <span className="badge">Promoção</span> 14 - Calabresa
        </h4>

        <p className="item-desc">Calabresa, cebola e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>41,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>43,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>45,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>53,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">15 - Toscana</h4>

        <p className="item-desc">Calabresa moída, catupiry e parmesão.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>56,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>68,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">16 - Toscana Especial</h4>

        <p className="item-desc">
          Calabresa moída, mussarela, catupiry, parmesão, decorada com pimentões
          coloridos refogados com cebola.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Slide left cascade duration={300}>
      <TitleSeparator>Aves</TitleSeparator>
    </Slide>

    <Item firstItem>
      <div className="item-separator">
        <h4 className="item-title">17 - Adventista</h4>

        <p className="item-desc">
          Peito de peru, palmito, catupiry, mussarela, ervilha e ovo.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">18 - Caipira</h4>

        <p className="item-desc">
          Frango desfiado, catupiry, milho, mussarela e tomate.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">19 - Frango</h4>

        <p className="item-desc">
          Frango desfiado, catupiry, bacon e mussarela.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>71,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">20 - Frango Crocante</h4>

        <p className="item-desc">
          Frango desfiado, catupiry, batata palha e mussarela.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>65,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>73,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">21 - Itália</h4>

        <p className="item-desc">
          Frango desfiado, catupiry, milho, palmito, ervilha, champignon e
          mussarela.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>65,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>67,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>75,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">22 - Frango Cream Cheese</h4>

        <p className="item-desc">Frango desfiado, mussarela e cream cheese.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>65,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>73,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">23 - Peito de Peru</h4>

        <p className="item-desc">
          Peito de Peru, mussarela, catupiry e tomate.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>71,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">24 - Peito de Peru Especial</h4>

        <p className="item-desc">Peito de peru, cream cheese e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">25 - Piracicabana</h4>

        <p className="item-desc">
          Frango desfiado, cheddar, mussarela e bacon.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>71,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">26 - Requinte</h4>

        <p className="item-desc">
          Frango desfiado, palmito, presunto, bacon, ervilha, catupiry e
          mussarela.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>65,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>67,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>75,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">27 - Tropical</h4>

        <p className="item-desc">
          Frango desfiado, mussarela, brócolis, palmito, champignon e creme de
          leite.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>65,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>67,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>75,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">28 - Veneza</h4>

        <p className="item-desc">
          Frango desfiado, palmito, champignon, catupiry, mussarela e parmesão.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>65,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>73,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Slide left cascade duration={300}>
      <TitleSeparator>Lombo Canadense</TitleSeparator>
    </Slide>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">29 - À Moda da Casa</h4>

        <p className="item-desc">
          Lombo, palmito, bacon, mussarela e parmesão.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>65,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>73,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">30 - Brasileira</h4>

        <p className="item-desc">Lombo, ovo, ervilha, milho e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>57,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>69,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">31 - Lombo</h4>

        <p className="item-desc">Lombo, catupiry e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>55,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>57,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>67,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">32 - Lombo Especial</h4>

        <p className="item-desc">Lombo, cebola, cheddar e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>70,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Slide left cascade duration={300}>
      <TitleSeparator>Presunto</TitleSeparator>
    </Slide>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">
          <span className="badge">Promoção</span> 33 - Americana
        </h4>

        <p className="item-desc">Presunto, ervilha e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>41,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>43,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>45,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>53,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">34 - À Moda do Pizzaiolo</h4>

        <p className="item-desc">
          Presunto, milho, ervilha, palmito e mussarela.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>70,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">35 - Da Hora</h4>

        <p className="item-desc">
          Presunto, champignon, catupiry, bacon e mussarela.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>70,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">36 - Forneiro</h4>

        <p className="item-desc">
          Presunto, azeitonas verdes picadas, mussarela e catupiry.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>56,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>68,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">37 - Francesa</h4>

        <p className="item-desc">
          Presunto, ovos, catupiry, mussarela e manjericão.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>70,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">38 - Francesa Especial</h4>

        <p className="item-desc">
          Presunto, ovo, palmito, cebola, bacon, tomate, catupiry, mussarela,
          pimenta e manjericão.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>65,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>67,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>75,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">39 - Napoli</h4>

        <p className="item-desc">
          Presunto, mussarela, catupiry, tomate e parmesão.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>56,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>68,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">
          <span className="badge">Promoção</span> 40 - Napolitana
        </h4>

        <p className="item-desc">Presunto, mussarela, tomate e manjericão.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>41,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>43,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>45,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>53,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">41 - Portuguesa</h4>

        <p className="item-desc">
          Presunto, catupiry, ervilha, milho, palmito, bacon, ovo, cebola em
          mussarela.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>65,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>67,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>75,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">
          <span className="badge">Promoção</span> 42 - Presunto
        </h4>

        <p className="item-desc">Presunto, mussarela e tomate.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>41,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>43,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>45,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>53,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">43 - Portuguesinha</h4>

        <p className="item-desc">
          Presunto, ovo, cebola, ervilha, palmito e mussarela.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>70,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Slide left cascade duration={300}>
      <TitleSeparator>Verduras</TitleSeparator>
    </Slide>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">44 - Brócolis</h4>

        <p className="item-desc">Brócolis, catupiry, bacon e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>70,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">
          <span className="badge">Promoção</span> 45 - Escarola
        </h4>

        <p className="item-desc">Escarola, catupiry, bacon e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>41,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>43,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>45,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>53,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">46 - Escarola com Aliche</h4>

        <p className="item-desc">Escarola, aliche, provolone e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>71,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">47 - havaiana</h4>

        <p className="item-desc">
          Brócolis, escarola, bacon, catupiry e provolone.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>71,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">48 - Naturitália</h4>

        <p className="item-desc">
          Brócolis, bacon, palmito, catupiry, mussarela e tomate seco.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>65,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>73,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">49 - Rúcula</h4>

        <p className="item-desc">
          Peito de peru, mussarela, tomate seco e rúcula fresca.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>70,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">50 - Vegetariana</h4>

        <p className="item-desc">
          Escarola, tomate seco, brócolis, champignon e mussarela.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>71,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">51 - Verona</h4>

        <p className="item-desc">
          Brócolis, palmito, creme de leite, mussarela e tomate seco.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">52 - Vegana</h4>

        <p className="item-desc">
          Escarola, palmito, brócolis, tomate, champignon e abobrinha.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>65,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>73,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Slide left cascade duration={300}>
      <TitleSeparator>Palmito</TitleSeparator>
    </Slide>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">53 - Alemã</h4>

        <p className="item-desc">
          Palmito, milho, catupiry, mussarela e batata palha.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>71,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">54 - Alpina</h4>

        <p className="item-desc">Palmito, mussarela, bacon e provolone.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>70,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">55 - Palmito</h4>

        <p className="item-desc">Palmito, tomate, catupiry e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>71,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">56 - Praiana</h4>

        <p className="item-desc">
          Palmito, champignon, mussarela, parmesão, ovo, cebola e ervilha.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">57 - Primavera</h4>

        <p className="item-desc">
          Palmito, mussarela, milho, ovo, presunto e cebola.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>71,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Slide left cascade duration={300}>
      <TitleSeparator>Milho</TitleSeparator>
    </Slide>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">
          <span className="badge">Promoção</span> 58 - Milho
        </h4>

        <p className="item-desc">Milho, catupiry e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>41,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>43,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>45,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>53,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">59 - Sertaneja</h4>

        <p className="item-desc">Milho, palmito e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>55,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>57,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>67,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">
          <span className="badge">Promoção</span> 60 - Paulista
        </h4>

        <p className="item-desc">Milho, ervilha, ovo e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>41,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>43,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>45,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>53,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">61 - Corn & Bacon</h4>

        <p className="item-desc">Milho, bacon, catupiry e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>57,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>69,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Slide left cascade duration={300}>
      <TitleSeparator>Variedades</TitleSeparator>
    </Slide>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">
          <span className="badge">Promoção</span> 62 - Alho
        </h4>

        <p className="item-desc">Alho crocante, mussarela e tomate.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>41,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>43,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>45,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>53,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">63 - Do Papa</h4>

        <p className="item-desc">
          Catupiry, tomate, parmesão salpicado com alho frito.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>70,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">64 - Peperone</h4>

        <p className="item-desc">Salame tipo peperone, catupiry e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>65,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>73,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">65 - Carne Seca</h4>

        <p className="item-desc">Carne seca refogada, catupiry e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>67,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>69,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>71,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>79,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">66 - Abobrinha</h4>

        <p className="item-desc">Abobrinha, mussarela, catupiry e parmesão.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>70,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">67 - Filé Mignon</h4>

        <p className="item-desc">Filé mignon em tiras, catupiry e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>75,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>77,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>79,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>87,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">68 - Filé Migon com Rúcula</h4>

        <p className="item-desc">Filé migon em tiras, rúcula e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>75,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>77,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>79,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>87,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">69 - Filé Mignon com Barbecue</h4>

        <p className="item-desc">Filé mignon em tiras, barbecue e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>77,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>79,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>81,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>89,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">70 - Strogonof de Frango</h4>

        <p className="item-desc">
          Strogonof de frango, mussarela e batata palha.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>74,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>76,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>84,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">71 - Strogonof de Mignon</h4>

        <p className="item-desc">
          Strogonof de carne, mussarela e batata palha.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>76,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>78,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>80,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>88,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Slide left cascade duration={300}>
      <TitleSeparator>Peixes</TitleSeparator>
    </Slide>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">72 - Aliche</h4>

        <p className="item-desc">
          Filé de anchovas, mussarela, tomate e provolone.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>57,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>69,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">73 - Atum</h4>

        <p className="item-desc">Atum, catupiry, cebola e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>71,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">74 - Atum Especial</h4>

        <p className="item-desc">
          Atum, mussarela, catupiry, decorada com pimentões refogados com
          cebola.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>65,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>73,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">75 - Camarão</h4>

        <p className="item-desc">Camarão refogado, mussarela e tomate.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>66,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>68,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>76,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">76 - Chefe</h4>

        <p className="item-desc">Atum, ervilha, palmito e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>71,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">77 - Peruana</h4>

        <p className="item-desc">
          Mussarela, palmito, atum, ovos, cebola e azeitonas verdes picadas.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>66,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>74,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Slide left cascade duration={300}>
      <TitleSeparator>Bacon</TitleSeparator>
    </Slide>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">78 - Bacon</h4>

        <p className="item-desc">Bacon, catupiry e mussarela.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>54,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>56,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>66,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">79 - Romana</h4>

        <p className="item-desc">Bacon, mussarela e cebola.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>54,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>56,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>66,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">80 - Siciliana</h4>

        <p className="item-desc">Bacon, presunto, mussarela e manjericão.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>56,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>58,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>68,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">81 - Siciliana 2</h4>

        <p className="item-desc">
          Bacon, champignon, cebola, presunto, catupiry e mussarela.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>59,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>61,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>63,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>70,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Slide left cascade duration={300}>
      <TitleSeparator>Novidades</TitleSeparator>
    </Slide>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">82 - Shitake</h4>

        <p className="item-desc">
          Mussarela, shitake, shimeji, cebola, tomate e parmesão.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>76,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>78,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>80,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>88,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">83 - Pera, Mel e Gorgonzola</h4>

        <p className="item-desc">
          Pera, mel de laranjeiras, gorgonzola e mussarela.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>76,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>78,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>80,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>88,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">84 - Alho Poró</h4>

        <p className="item-desc">Mussarela, alho poró e cream cheese.</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>60,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>62,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>72,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">85 - Frango c/ Alho Poró</h4>

        <p className="item-desc">
          Frango desfiado, mussarela, alho poró, cream cheese.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>76,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>78,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>80,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>88,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">86 - Peito de Peru c/ Alho Poró</h4>

        <p className="item-desc">
          Peito de peru, mussarela, cream cheese e alho poró.
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>76,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>78,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>80,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>88,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">87 - Pernil</h4>

        <p className="item-desc">
          Pernil suíno desfiado, catupiry, mussarela e (Opcional: cebola).
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>P</p>
          </div>
          <div className="item-price">
            <p>64,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size2">
            <p>M</p>
          </div>
          <div className="item-price">
            <p>66,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>68,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>76,00</p>
          </div>
        </div>
      </div>
    </Item>
  </>
);

export default Pizzas;
