import React from 'react';

import { Item } from '../styles/styles.js';

const Bordas = () => (
  <>
    <Item>
      <div className="item-separator">
        <h4 className="item-title">Bordas Extras</h4>

        <p className="item-desc">
          Bacon - Calabresa - Frango - Presunto - Brócolis - Palmito - Mussarela
        </p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>18,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>19,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">Borda de Catupiry</h4>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>14,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>16,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">Borda de Cream Cheese</h4>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>14,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>16,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">Borda de Cheddar</h4>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>15,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>17,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">Borda Vulcão</h4>

        <p className="item-desc">Catupiry ou Cheddar</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>25,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>27,00</p>
          </div>
        </div>
      </div>
    </Item>

    <Item>
      <div className="item-separator">
        <h4 className="item-title">Borda de Chocolate</h4>

        <p className="item-desc">Preto ou Branco</p>
      </div>

      <div className="item-price-container">
        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size1">
            <p>G</p>
          </div>
          <div className="item-price">
            <p>17,00</p>
          </div>
        </div>

        <div className="item-price-wrapper">
          <div className="item-price-size bgcolor-size3">
            <p>F</p>
          </div>
          <div className="item-price">
            <p>18,00</p>
          </div>
        </div>
      </div>
    </Item>
  </>
);

export default Bordas;
